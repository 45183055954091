import React from 'react'
import styled from '@emotion/styled'
import algoliasearch from 'algoliasearch/lite'
import {
  connectRefinementList,
  createInfiniteHitsSessionStorageCache,
  ClearRefinements,
  Configure,
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-dom'
import CustomInfiniteHits from '@components/InfiniteHits'
import PageTitle from '@components/PageTitle'
import { Section } from '@styles/globalComponents'

const searchClient = algoliasearch(
  '2SZNF2E322',
  '3d3a1e0019b22283fb8c9e77990f4afd'
)
const sessionStorageCache = createInfiniteHitsSessionStorageCache()

const StaticRefinementList = connectRefinementList(
  ({ values, currentRefinement, items, refine }) => (
    <List className="ais-RefinementList-list">
      {values.map((staticItem) => {
        const { isRefined } = items.find(
          (item) => item.label === staticItem.label
        ) || {
          isRefined: false,
        }
        return (
          <li key={staticItem.value}>
            <input
              className={isRefined ? `${'checked'}` : undefined}
              id={staticItem.value}
              type="checkbox"
              value={staticItem.value}
              name={staticItem.value}
              onChange={(event) => {
                const value = event.currentTarget.value
                const next = currentRefinement.includes(value)
                  ? currentRefinement.filter((current) => current !== value)
                  : currentRefinement.concat(value)
                refine(next)
              }}
            />
            <label htmlFor={staticItem.value}>{staticItem.label}</label>
          </li>
        )
      })}
    </List>
  )
)

const Search = () => {
  // Todo: Highlight
  return (
    <SearchSection>
      <PageTitle>All Recipes</PageTitle>
      <Container>
        <InstantSearch
          searchClient={searchClient}
          indexName="THE_WHISTLING_COOK_BLOG"
        >
          <Configure hitsPerPage={12} />
          <RefinementContainer>
            {/* <ClearRefinements /> */}
            <RefinementCategory>
              <RefinementTitle>By Recipe Type</RefinementTitle>
              <StaticRefinementList
                attribute="tags.title"
                operator="and"
                values={[
                  { label: 'Pastries', value: 'Pastries' },
                  { label: 'Cookies', value: 'Cookies' },
                  { label: 'Cupcakes', value: 'Cupcakes' },
                  { label: 'Bread', value: 'Bread' },
                ]}
              />
            </RefinementCategory>
            {/* <RefinementCategory>
              <RefinementTitle>By Seasons</RefinementTitle>
              <StaticRefinementList
                attribute="tags.title"
                operator="and"
                values={[
                  { label: 'Winter', value: 'Winter' },
                  { label: 'Spring', value: 'Spring' },
                  { label: 'Summer', value: 'Summer' },
                  { label: 'Autumn', value: 'Autumn' },
                ]}
              />
            </RefinementCategory> */}
            <RefinementCategory>
              <RefinementTitle>By Meal Type</RefinementTitle>
              <StaticRefinementList
                attribute="tags.title"
                operator="and"
                values={[
                  { label: 'Breakfast', value: 'Breakfast' },
                  { label: 'Lunch', value: 'Lunch' },
                  { label: 'Entree', value: 'Entree' },
                  { label: 'Dessert', value: 'Dessert' },
                ]}
              />
            </RefinementCategory>
            </RefinementContainer>
          <SearchContainer>
            <SearchBox translations={{ placeholder: 'Search here...' }} />
          </SearchContainer>
          <ResultContainer>
            <CustomInfiniteHits cache={sessionStorageCache} />
          </ResultContainer>
          {/* <PaginationContainer>
            <Pagination
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
          </PaginationContainer> */}
        </InstantSearch>
      </Container>
    </SearchSection>
  )
}

// const PaginationContainer = styled.div`
//   grid-area: pagination;
//   margin: 4rem 0;
//   width: 100%;
// `

const SearchSection = styled(Section)`
  padding: 5em 0 2em;
  max-width: 1250px;
`

const Container = styled.div`
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    display: grid;
    column-gap: 3rem;
    grid-template-rows: 80px auto auto;
    grid-template-columns: 150px auto auto;
    grid-template-areas:
      'refine search search'
      'refine content content'
      'refine content content';
  }
`

const RefinementContainer = styled.div`
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.screen.medium}) {
    display: flex;
    flex-flow: row wrap;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: refine;
    justify-self: center;
  }
`

const RefinementCategory = styled.div`
  padding: 0 0 1em 1em;
  width: max-content;
`

const RefinementTitle = styled.h2`
  margin-bottom: 0.5em;
  font-size: 1.1em;
  padding: 0.5em 0;
  border-bottom: 1px solid black;
`

const SearchContainer = styled.div`
  margin-left: 0.4em;
  width: 300px;
  white-space: nowrap;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: search;
    margin-left: 0;
  }
`

const ResultContainer = styled.div`
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: content;
  }
`

const List = styled.ul`
  input::before {
    width: 10px;
    height: 10px;
    border: 1px solid gray;
    border-radius: 1px;
    background: transparent;
    position: relative;
    display: inline-block;
    margin-right: 1ex;
    content: '';
  }
  input:checked::before {
    background: ${(props) => props.theme.colors.secondary};
  }

  label {
    font-size: 0.9em;
  }
`

export default Search
