import React from 'react'
import SEO from '@components/SEO'
import Layout from '@components/Layout'
import Search from '@components/Search'
import LogoContainer from '@components/Logo'

const Recipes = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO
        title="All Recipes"
        description="All recipes on The Whistling Cook"
      />
      <LogoContainer />
      <Search />
    </Layout>
  )
}

export default Recipes
